.contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: #799BA8;
	min-height: calc(100vh - 54px);
	h1{

	}
	#contactsDiv{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10vw;
		width: 70vw;
		.contactDiv {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 33%;
			.contactImg{
				width: 8vw;
			}
			.contactText{
				font-weight: bold;
			}
			a:link {
				color: #3ADDDD;
				text-decoration: none;
			}
			a:visited {
				color: #3EC9C7;
				text-decoration: none;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.contact {
		width: 100vw;
		min-height: 100vh;
		margin-top: 11vw;
		#contactsDiv {
			flex-direction: column;
			.contactDiv {
				width: 50%;
				.contactImg {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}