.project {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #799BA8;
	min-height: calc(100vh - 54px);
	h1{
		text-align: center;
		margin-top: 0;
		padding-bottom: 1.5rem;
	}
	#projectsDiv{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		width: 100vw;
		.projectDiv{
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 35%;
			margin-bottom: 3rem;
			.projectSec{
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 20rem;
				height: 11.26rem;
				border-radius: 10px;
				.projectDescription{
					display: none;
					font-size: .95rem;
					text-align: justify;
					padding: 2rem;

					a{
						display: none;
						width: 100%;
						text-align: center;
						color: #3ADDDD;
						font-size: 1.25rem;
						font-weight: bold;
						cursor: pointer;
					}
				}
				
			}
			#hammerdogSec {
				background-image: url('../assets/hammerdog.jpg');
				background-size: cover;
			}
			#jackboxSec {
				background-image: url('../assets/jbpp5.jpg');
				background-size: cover;
			}
			#loaSec {
				background-image: url('../assets/loa.png');
				background-size: cover;
			}
			#transactionSec {
				background-image: url('../assets/ta.png');
				background-size: cover;
			}
			.projectName {
				font-size: 1.25rem;
				font-weight: bold;
				color: #3ADDDD;
				margin: 1rem 0;
			}	
		}
		.projectDiv:hover {
			border-radius: 10px;
			.projectSec{
				width: 50vw;
				height: 28.17vw;
				z-index: 100;
				position: fixed;
				left:25vw;
				top: 25vh;
				background-color: #a9a9a9;
				background-blend-mode: multiply;
				.projectDescription{
					display: inline-block;
					color: white;
					backdrop-filter: blur(20px);
					height: 100%;
					margin: 0;
					vertical-align: middle;
					a {
						display: inline-block;
						text-align: center;
					}
				}
			}
			.projectName {
				display: none;
			}
		}
	}	
}

@media only screen and (max-width: 768px) { 
	.project {
		width: 100vw;
		margin-top: 11vw;
		#projectsDiv{
			.projectDiv{
				width: 100vw;
			}
			.projectDiv:hover {
				.projectSec{
					width: 100vw;
					height: 60vh;
					left: 0;
					top: 20;
				}
			}
		}
	}
}