.skills {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 54px);
	h1{
		text-align: center;
		color: #000;
		padding-bottom: 1.5rem;
	}
	.skillsDiv {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 50vw;
		.skillDiv {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			width: 4rem;
			.skillImg {
				width: 4rem;
			}
			.skillName {
				text-align: center;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.skills {
		width: 100vw;
		margin-top: 11vw;
		background-color: #FFF;
		.skillsDiv {
			width: 70vw;
			.skillDiv {
				padding: 0;
				width: 50%;
				.skillImg{
					width: 70%;
				}
			}
		}
	}
}