.solutions {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 54px);
	background: linear-gradient(45deg, #799BA8 53%, #31979E 45%);
	#solutionsHeader{
		display: flex;
		flex-direction: column;
		align-items: center;
		h1{
			margin-bottom: 0;
		}
		h3{
			font-weight: normal;
		}
	}
	.solutionsDiv {
		display: flex;
		flex-direction: row;
		margin-top: 1rem;
		width: 70vw;
		#textDiv{
			width: 65%;
			#solutionsText{
				margin-top: 13rem;
				margin-right: 5rem;
				text-align: justify;
				color: #FFF;
			}
		}
		#imgDiv {
			width: 35%;
			#solutionsImg {
				width: 90%;
				border: 5px solid #FFF;
				border-radius: 10px;
				margin-bottom: 3rem;
			}
		}
	}
}

@media only screen and (max-width: 768px) { 
	.solutions {
		margin-top: 11vw;
		#solutionsHeader {
			width: 90vw;
			text-align: center;
			h1 {

			}
		}
		.solutionsDiv {
			flex-direction: column-reverse;
			align-items: center;
			#imgDiv {
				width: 60vw;
				#solutionsImg {
					width: 100%;
					border: none;
					margin-bottom: none;
				}
			}
			#textDiv {
				width: 70vw;
				#solutionsText{
					width: 100%;
					margin-top: 0;
				}
			}
		}
	}
}