@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto'),
		 local('Robot-Regular'),
		 url(../fonts/Roboto-Regular.ttf) format('ttf');
}

.splash {
	display: flex;
	flex-direction: center;
	justify-content: center;
	background-color: #000;
	height: calc(100vh - 50px);
	font-family: 'Roboto', sans-serif;
	.binary {
		margin-bottom: 0;
		.line {
			display: flex;
			justify-content: center;
			.lineUl {
				display: flex;
				flex-direction: row;
				color: #3DBAC4;
				margin-top: .2rem;
				margin-bottom: .2rem;
				
				.lineLi {
					list-style-type: none;
				}
				.blink0 {
				  animation: blink 1.5s step-start 0s infinite;
				  -webkit-animation: blink 1.5s step-start 0s infinite;
				}
				.blink1 {
				  animation: blink 1.5s step-start 0s infinite;
				  -webkit-animation: blink 1.5s step-start 0s infinite;
				}
				.blink2 {
				  animation: blink 1s step-start 1s infinite;
				  -webkit-animation: blink 1s step-start 1s infinite;
				}
				.blink3 {
				  animation: blink 0.5s step-start 2s infinite;
				  -webkit-animation: blink 0.5s step-start 2s infinite;
				}
				.blink4 {
				  animation: blink 1.5s step-start 3s infinite;
				  -webkit-animation: blink 1.5s step-start 3s infinite;
				}
				.blink5 {
				  animation: blink 1s step-start 0s infinite;
				  -webkit-animation: blink 1s step-start 0s infinite;
				}
				.blink6 {
				  animation: blink 1.5s step-start 2s infinite;
				  -webkit-animation: blink 1.5s step-start 2s infinite;
				}
				.blink7 {
				  animation: blink 1s step-start 3s infinite;
				  -webkit-animation: blink 1s step-start 3s infinite;
				}
				.blink8 {
				  animation: blink 0.5s step-start 0s infinite;
				  -webkit-animation: blink 0.5s step-start 0s infinite;
				}
				.blink9 {
				  animation: blink 1.5s step-start 1s infinite;
				  -webkit-animation: blink 1.5s step-start 1s infinite;
				}
				.blink10 {
				  animation: blink 1s step-start 2s infinite;
				  -webkit-animation: blink 1s step-start 2s infinite;
				}
				.blink11 {
				  animation: blink 0.5s step-start 3s infinite;
				  -webkit-animation: blink 0.5s step-start 3s infinite;
				}
				.blink12 {
				  animation: blink 1.5s step-start 0s infinite;
				  -webkit-animation: blink 1.5s step-start 0s infinite;
				}
				.blink13 {
				  animation: blink 1s step-start 1s infinite;
				  -webkit-animation: blink 1s step-start 1s infinite;
				}
				.blink14 {
				  animation: blink 0.5s step-start 2s infinite;
				  -webkit-animation: blink 0.5s step-start 2s infinite;
				}
				.blink15 {
				  animation: blink 1.5s step-start 3s infinite;
				  -webkit-animation: blink 1.5s step-start 3s infinite;
				}
				.blink16 {
				  animation: blink 1s step-start 0s infinite;
				  -webkit-animation: blink 1s step-start 0s infinite;
				}
				.blink17 {
				  animation: blink 0.5s step-start 1s infinite;
				  -webkit-animation: blink 0.5s step-start 1s infinite;
				}
				.blink18 {
				  animation: blink 1.5s step-start 2s infinite;
				  -webkit-animation: blink 1.5s step-start 2s infinite;
				}
				.blink19 {
				  animation: blink 1s step-start 3s infinite;
				  -webkit-animation: blink 1s step-start 3s infinite;
				}
			}
		}
	}
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		#brandLine {
			display: flex;
			justify-content: center;
			color: white;
			// width: 50vw;
			.brand {
				font-size: 48px;
			}
			#myka {
				width: 20vw;
				text-align: right;
			}
			#in {
				// margin-left: 3rem;
				// margin-right: 3rem;
				width: 10vw;
				text-align: center;
			}
			#tech {
				width: 20vw;
				text-align: left;
			}
		}
	}
	// #brandLine {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	color: white;
	// 	width: 50vw;
	// 	.brand {
	// 		font-size: 48px;
	// 	}
	// 	#myka {
	// 		width: 20vw;
	// 	}
	// 	#in {
	// 		// margin-left: 3rem;
	// 		// margin-right: 3rem;
	// 		width: 10vw;
	// 	}
	// 	#tech {
	// 		width: 20vw;
	// 	}
	// }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) { 
	.splash {
		overflow-x: hidden;
		width: 100vw;
		margin-top: 11vw;
		.container {
			display: flex;
			align-items: center;
			#brandLine {
				padding-left: 10vw;
				.brand {
					font-size: 2rem;
				}
				#myka{
					width: 40vw;
				}
				#in{
					width: 20vw;
					text-align: center;
				}
				#tech{
					width: 40vw;
				}
			}
		}
	}
}