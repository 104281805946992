.App {
  .header {
  	display: flex;
  	justify-content: center;
    background-color: #000;
    z-index: 100;
    position: sticky;
    top: 0;
    #menu{
    	display: flex;
	  	flex-direction: row;
	  	justify-content: space-around;
	  	align-items: center;
	  	width: 66vw;
	    #logo {
	      width: 2vw;
	    }
	    a {
	    	text-decoration: none;
	    	.menuItem {
		    	color: #FFF;
		    	font-style: none;
		    }
	    }
	}
	#navbar {
		display: none;
	}
  }
}

@media only screen and (max-width: 768px) { 
	.App {
		width: 100vw;
		background-color: black;
		.header{
			display: inline-block;
			justify-content: left;
			height: 2rem;
			position: fixed;
			top: 0;
		 	#menu {
			 	display: none;
			}
			#navbar {
				display: inline-block;
				background-color: black;
				width: 100vw;
				#basic-nav-dropdown {
					// z-index: 1000;
					width: 80vw!important;
					height: 100vh;
					img {
						width: 6vw;
						padding: .5rem;
					}
				}
			}
		}
	}
	.navLink {
		color: #3ADDDD!important;
		font-weight: bold;
		font-size: 1.3rem;
		text-decoration: none;
		padding-left: 1rem;
	}

}