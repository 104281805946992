.about {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 54px);
	background: linear-gradient(135deg, #799BA8 38%, #31979E 35%);
	h1{

	}
	.aboutDiv {
		display: flex;
		flex-direction: row;
		width: 70vw;
		margin-top: 3rem;
		#imageDiv {
			width: 35%;
			#aboutImg {
				border: 5px solid #FFF;
				border-radius: 500%;
				width: 16vw;
				height: 16vw;
			}
		}
		#textDiv {
			width: 65%;
			margin-top: 8rem;
			#aboutText{
				color: #FFF;
				text-align: justify;
			}
		}
	}
}

@media only screen and (max-width: 768px) { 
	.about {
		width: 100vw;
		margin-top: 11vw;
		.aboutDiv {
			flex-direction: column;
			align-items: center;
			width: 70vw;
			margin-top: 0;
			#imageDiv{
				width: 50%;
				#aboutImg{
					width: 100%;
					height: auto;
				}
			}
			#textDiv {
				width: 100%;
				margin: 0;
			}
		}
	}
}
